<template>
  <div class="">
    <!-- <center-title title="企业基本信息" :border="false"></center-title> -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="162px"
      class="ruleForm"
    >
      <el-form-item label="统一社会信用代码" prop="credit_code">
        <el-input
          v-model="ruleForm.credit_code"
          placeholder="统一社会信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="法人代表" prop="legal_person">
        <el-input
          v-model="ruleForm.legal_person"
          placeholder="法人代表"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="id_card">
        <el-input
          v-model="ruleForm.id_card"
          placeholder="身份证号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证有效期" prop="id_card_expire">
        <el-date-picker
          v-model="ruleForm.id_card_expire"
          type="date"
          placeholder="身份证有效期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="注册资本" prop="register_capital">
        <el-input
          v-model="ruleForm.register_capital"
          placeholder="注册资本"
        ></el-input>
      </el-form-item>
      <el-form-item label="成立日期" prop="found_date">
        <el-date-picker
          v-model="ruleForm.found_date"
          type="date"
          placeholder="成立日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="营业期限" prop="business_type">
        <el-radio-group v-model="ruleForm.business_type">
          <el-radio label="1">长期有效</el-radio>
          <el-radio label="0">短期有效</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="期限范围"
        prop="businessterm"
        v-if="ruleForm.business_type && ruleForm.business_type == 0"
      >
        <el-date-picker
          v-model="ruleForm.businessterm"
          type="daterange"
          start-placeholder="营业期起始日"
          end-placeholder="营业期结束日"
          @change="
            (date) => (
              (ruleForm.business_start = date[0]),
              (ruleForm.business_end = date[1])
            )
          "
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="开户名称" prop="account_name">
        <el-input
          v-model="ruleForm.account_name"
          placeholder="开户名称"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="account_bank">
        <el-input
          v-model="ruleForm.account_bank"
          placeholder="开户银行"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户账号" prop="account_number">
        <el-input
          v-model="ruleForm.account_number"
          placeholder="开户账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="一般纳税人资格" prop="taxpayer_qualification">
        <el-radio-group v-model="ruleForm.taxpayer_qualification">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          class="white md bg-primary"
          @click="submitForm('ruleForm')"
        >
          下一步
        </el-button>
        <el-button class="reset bg-center white md" @click="$emit('toPrev')">
          上一步
        </el-button>
        <el-button
          class="reset bg-center white md"
          @click="resetForm('ruleForm')"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../../../components/CenterTitle";
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        credit_code: "", //统一社会信用代码
        legal_person: "", //法人代表
        id_card: "", //身份证号码
        id_card_expire: "", //身份证有效期
        register_capital: "", //注册资本
        found_date: "", //成立日期
        business_type: "", //营业期限
        businessterm: "", //营业期限范围
        business_start: "", //营业开始日期
        business_end: "", //营业结束日期
        account_name: "", //开户名称
        account_bank: "", //开户银行
        account_number: "", //开户账号
        taxpayer_qualification: "", //一般纳税人资格
      },
      rules: {
        credit_code: vxRule(true, "USCC", "blur", "请输入统一社会信用代码"),
        legal_person: vxRule(true, null, "blur", "请输入法人代表"),
        id_card: vxRule(true, "IdCard", "blur", "请输入身份证号码"),
        id_card_expire: vxRule(true, null, "change", "请选择身份证有效期"),
        register_capital: vxRule(true, null, "blur", "请输入注册资本"),
        found_date: vxRule(true, null, "change", "请选择成立日期"),
        business_type: vxRule(true, null, "change", "请选择营业期限"),
        businessterm: vxRule(true, null, "change", "请选择营业期限"),
        account_name: vxRule(true, null, "blur", "请输入开户名称"),
        account_bank: vxRule(true, null, "blur", "请输入开户银行"),
        account_number: vxRule(true, "Bank", "blur", "请输入开户账号"),
        taxpayer_qualification: vxRule(
          true,
          null,
          "change",
          "请选择一般纳税人资格"
        ),
      },
      value: [],
    };
  },
  computed: {},
  watch: {
    companyInfo: {
      handler(val) {
        for (const key in this.ruleForm) {
          this.ruleForm[key] = val[key] + "";
        }
      },
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitEmit", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped></style>
